<script>
import Layout from "@/views/layouts/main.vue";
import appConfig from "@/app.config.json";

import {AgGridVue} from 'ag-grid-vue'
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';

import groupCell from "@/views/(duplicate)/maclTalk/groupCell.vue";
import editBtn from "@/views/(duplicate)/maclTalk/editMemberBtn.vue"

import axios from "axios";
import {mapState} from "vuex";
import {groupInfoList, groupInfoMethod, loggedInfoObject} from "@/state/helpers";
import {msgConfirmOptCenter, msgModalOptCenter} from "@/components/globalFunctions";


export default {
  components: {
    Layout,
    AgGridVue,
    /*sendBlock: sendBlockBtn*/
  },
  page: {
    title: "수신자 관리",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "수신자 관리",
      items: '마클톡 수신자 목록을 관리합니다.',
      gridApi: null,
      columnApi: null,
      columnDefs: [
        {headerName: '고객명', field: 'name', checkboxSelection: true, headerCheckboxSelection: true},
        {headerName: '연락처', field: 'phone', maxWidth: 140},
        {headerName: '상태', field: 'state', maxWidth: 70},
        /* {headerName: '수신관리', field: 'name', cellRendererFramework: sendBlockBtn, maxWidth: 98},*/
        {
          headerName: '포함그룹',
          field: '',
          maxWidth: 200,
          wrapText: true,
          autoHeight: true,
          cellRendererFramework: groupCell
        },
        {headerName: '등록일시', field: 'reg_date'},
        {headerName: '관리', field: '', cellRendererFramework: editBtn, maxWidth: 140},
        /*{headerName: '삭제', field: '', cellRendererFramework: delBtn, maxWidth: 76}*/
      ],
      talkList: [],
      context: {},
      newMember: {
        'name': '',
        'phone': '',
      },
      excelFile: '',
      today: new Date(),
      groupEditList: [],
      groupNameList: [],
      selectGroupName: 'all',
      editName: '',
      selGroupSrl: 'select',

    };
  },
  computed: {
    ...mapState(loggedInfoObject, groupInfoList),
  },
  beforeMount() {
    //ag-grid 부모 자식간 통신을 위해 필요함
    this.context = {
      componentParent: this
    }
  },
  mounted() {
    this.callMemberList()
    this.getGroupList()
  },
  methods: {
    ...groupInfoMethod,
    //Ag-grid
    /*onFirstDataRendered() {
      //event.api.sizeColumnsToFit()
      //event.api.autoSizeColumns(true)
      console.log(event)
      //this.columnApi.autoSizeAllColumns(false)
    },*/
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(this.$refs.default_input.value)
    },
    onGridReady(params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
    },
    getSelectedRowData() {
      let selectNodes = this.gridApi.getSelectedNodes()
      let selectData = selectNodes.map(node => node.data)
      /*console.log(selectData)
      console.log(JSON.stringify(selectData[0].mk_custom_srl))*/
      var list = []
      selectData.forEach(function (item) {
        list.push(item.mk_custom_srl)
      })
      this.groupEditList = list
      //console.log(this.sendMemberList.toString())
    },

    //수신자 리스트 가져오기
    callMemberList() {
      //let url = `/api/kakao/custom/${this.logged_info.mart_srl}`
      let url = `/api/web/market/group/member/vue/${this.logged_info.mart_srl}/all`
      axios.get(url).then((response) => {
        //console.log(response.data)

        this.talkList = response.data.reverse()
      }).catch(err => console.log(err))
    },

    //고객 전화번호 추가
    addPhoneNum() {
      let url = `/api/kakao/custom/${this.logged_info.mart_srl}`

      //console.log('newMember', this.newMember)

      if (this.newMember.name == '') {
        this.$bvModal.msgBoxOk('등록하실 수신자 성명을 입력해주세요.', msgModalOptCenter)
      } else if (this.newMember.phone == '') {
        this.$bvModal.msgBoxOk('등록하실 수신자 연락처를 입력해주세요.', msgModalOptCenter)
      } else {
        axios.post(url, this.newMember).then((res) => {
          //console.log(res.data)

          if (res.data.status == 'fail') {
            if (res.data.msg === 'already exists') {
              this.$bvModal.msgBoxOk('이미 등록된 고객번호입니다.', msgModalOptCenter)
            } else {
              this.$bvModal.msgBoxOk('등록에 실패하였습니다.', msgModalOptCenter)
            }

          } else {
            this.$bvModal.msgBoxOk('등록되었습니다.', msgModalOptCenter)

            this.newMember.name = ''
            this.newMember.phone = ''

            this.callMemberList()
          }
        }).catch(err => console.log(err))
      }

    },

    //마트 내 수신자 전체 삭제
    delAllMember() {
      let url = `/api/kakao/customs/${this.logged_info.mart_srl}`

      this.$bvModal.msgBoxConfirm('※ 주의\n\n등록되어 있는 수신자를 모두 삭제하시겠습니까?', msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              axios.delete(url)
                  .then((res) => {
                    console.log(res.data)

                    this.callMemberList()
                  })
                  .catch(err => console.log(err))
            }
          })
    },

    //upload 엑셀
    uploadExcel() {
      console.log('uploadExcel')

      let url = `/api/kakao/custom/file/excel/${this.logged_info.mart_srl}`

      let fd = new FormData()

      fd.append('excel', this.excelFile)

      if (this.excelFile != '') {
        axios.post(url, fd).then((res) => {
          console.log(res.data)
          if (res.data.status == 'fail') {
            this.$bvModal.msgBoxOk(`${res.data.msg}`, msgModalOptCenter)
          } else {
            this.$bvModal.msgBoxOk('성공적으로 등록되었습니다.', msgModalOptCenter)
            this.callMemberList()
            this.$refs.fileRef.value = ''
          }
        }).catch(err => console.log(err))
      } else {
        this.$bvModal.msgBoxOk('파일이 존재하지 않습니다.\n파일을 선택해주세요.', msgModalOptCenter)
      }
    },

    //엑셀 파일 선택
    selectExcel() {
      let input = event.target;
      console.log(input.files[0])
      this.excelFile = input.files[0]
    },

    //엑셀 다운로드
    downExcel() {
      console.log('downExcel', this.talkList[0])

      let XLSX = require('xlsx')
      let tmpList = []

      let tmpObject = {
        '고객명': '',
        '연락처': '',
        '주소': '',
        '상세주소': '',
        '수신상태': '',
        '등록일시': ''
      }

      for (let i = 0; i < this.talkList.length; i++) {

        tmpObject = {
          '고객명': '',
          '연락처': '',
          '전체주소': '',
          '수신상태': '',
          '등록일시': ''
        }

        tmpObject['고객명'] = this.talkList[i].name
        tmpObject['연락처'] = this.talkList[i].phone
        tmpObject['수신상태'] = this.talkList[i].state

        if (this.talkList[i].addr == '' && this.talkList[i].addr_detail == '') {
          tmpObject['전체주소'] = ''
        } else if (this.talkList[i].addr != '' && this.talkList[i].addr_detail == '') {
          tmpObject['전체주소'] = this.talkList[i].addr
        } else {
          tmpObject['전체주소'] = this.talkList[i].addr + ' ' + this.talkList[i].addr_detail
        }

        console.log(this.talkList[i], this.talkList[i].addr, this.talkList[i].addr_detail)

        tmpObject['등록일시'] = this.talkList[i].reg_date

        tmpList.push(tmpObject)
      }

      const workBook = XLSX.utils.book_new()
      const workSheet = XLSX.utils.json_to_sheet(tmpList)
      XLSX.utils.book_append_sheet(workBook, workSheet, `수신자목록_${this.toStringByFormatting(this.today)}`)
      XLSX.writeFile(workBook, `수신자목록_${this.toStringByFormatting(this.today)}.xlsx`)
    },

    toStringByFormatting(source) {
      //console.log(new Date().toJSON())
      const year = source.getFullYear();
      const month = this.leftPad(source.getMonth() + 1);
      const day = this.leftPad(source.getDate());

      let time = year + month + day

      return time
    },

    //날짜 formating
    leftPad(value) {
      if (value >= 10) {
        return value;
      }
      return `0${value}`
    },

    //그룹
    getGroupList() {
      let url = `/api/web/market/group/vue/${this.logged_info.mart_srl}`

      axios.get(url).then((res) => {
        //console.log(res.data)

        this.groupNameList = res.data
        this.setGroupList(this.groupNameList)
      }).catch(err => console.log(err))
    },

    //그룹 지정
    changeGroup() {
      console.log(this.selGroupSrl)
      this.getSelectedRowData()

      if (this.groupEditList.length == 0) {
        this.$bvModal.msgBoxOk('한명 이상의 회원을 선택하세요', msgModalOptCenter)
      } else {
        this.$bvModal.msgBoxConfirm(`[${this.groupEditList.length}]명을 그룹 지정 하시겠습니까?`, msgConfirmOptCenter)
            .then((sel) => {
              if (sel) {
                let url = `/api/web/market/group/member/list/${this.logged_info.mart_srl}/${this.selGroupSrl}`
                let param = {'mk_custom_srl': this.groupEditList.toString()}

                axios.post(url, param)
                    .then((res) => {
                      console.log(res.data)
                      if (res.data.status == 'success') {
                        this.$bvModal.msgBoxOk('그룹이 지정되었습니다.', msgModalOptCenter)
                        this.callMemberList()
                      }
                    })
                    .catch(err => console.log(err))
              }
            })
      }
    },

    setGroupSrlValue(val) {
      this.selGroupSrl = val
    },

    //그룹 선택
    selectGroup(val) {
      this.talkList = []

      if (val == 'all') {
        this.callMemberList()
      } else {
        let url = `/api/web/market/group/member/vue/${this.logged_info.mart_srl}/${val}`

        axios.get(url).then((res) => {
          //console.log(res.data)

          this.talkList = res.data.reverse()
        }).catch(err => console.log(err))
      }
    },

    //그룹관리 methods
    newGroup(bvModalEvent) {
      let url = `/api/web/market/group/${this.logged_info.mart_srl}`
      let param = {'name': this.name}

      if (this.name == '') {
        this.$bvModal.msgBoxOk('그룹명을 입력하세요.', msgModalOptCenter)
        bvModalEvent.preventDefault()
      } else {
        axios.post(url, param).then((res) => {
          //console.log(res.data)
          if (res.data.status == 'success') {
            this.$bvModal.msgBoxOk('등록이 완료되었습니다.', msgModalOptCenter)
          }
          this.getGroupList()
        }).catch(err => console.log(err))
      }
    },

    editGroup(name, srl, bvModalEvent) {
      if (name == '') {
        this.$bvModal.msgBoxOk('그룹명을 입력하세요.', msgModalOptCenter)
        bvModalEvent.preventDefault()
      } else {
        this.$bvModal.msgBoxConfirm(`그룹명을 [${name}](으)로 변경하시겠습니까?`, msgConfirmOptCenter)
            .then((sel) => {
              if (sel) {
                let url = `/api/web/market/group/${this.logged_info.mart_srl}`

                let param = {'name': name, 'mk_group_srl': srl}

                axios.put(url, param)
                    .then(() => {
                      //console.log(res.data)
                      this.getGroupList()
                    })
                    .catch(err => console.log(err))
              }
            })
      }
    },

    deleteGroup(val) {
      let str = ''

      if (val.customer_cnt > 0) {
        str = '회원정보가 있는 그룹입니다. 삭제하시겠습니까?'
      } else {
        str = `[${val.gname}] 그룹을 삭제하시겠습니까?`
      }

      this.$bvModal.msgBoxConfirm(str, msgConfirmOptCenter)
          .then((sel) => {
            if (sel) {
              let url = `/api/web/market/group/${this.logged_info.mart_srl}`
              let param = {'mk_group_srl': val.mk_group_srl}

              axios.delete(url, {data: param})
                  .then((res) => {
                    //console.log(res.data)
                    if (res.data.status == 'success') {
                      this.$bvModal.msgBoxOk('삭제가 완료되었습니다.', msgModalOptCenter)
                    }
                    this.getGroupList()
                  })
                  .catch(err => console.log(err))
            }
          })
    },

  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
        <span style="float: left; padding-left: 8px;">
          <h4 class="mb-0 font-size-18">{{ title }} </h4>
          <span :items="items" class="font-size-14" style="color: #777777; text-align: left;">{{ items }}</span>
        </span>
          <span class="page-title-right d-flex align-items-center md-0" style="float: right; padding-right: 8px;">
<!--            <b-dropdown split text="그룹관리" variant="primary">
                <b-dropdown-item-button
                    v-for="row in groupNameList"
                    :key="row.mk_group_srl"
                    @click="changeGroup(row.mk_group_srl)"
                >
                  {{ row.gname }}
                </b-dropdown-item-button>
            </b-dropdown>-->

            <!--            <b-button
                variant="primary"
                @click="$bvModal.show('groupManagement')"
            >
              그룹관리
            </b-button>
              <b-modal
                  centered
                  id="groupManagement"
                  ok-only
                  ok-title="닫기"
                  title="그룹관리"
              >
                <div class="modal-body">
                <div class="table-responsive mb-0">
                  <table class="table" id="coupon_list">


                    <thead class="table-light" style="text-align: center;">
                    <tr>
                      <th>번호</th>
                      <th>그룹명</th>
                      <th>관리</th>
                    </tr>
                    </thead>


                    <tbody style="text-align: center;">

                    <tr
                        v-for="row in groupNameList"
                        :key="row.mk_group_srl"
                        style="vertical-align: middle;"
                    >
                      <td>{{ row.mk_group_srl }}</td>
                      <td>{{ row.gname }}</td>
                      <td class="tbl-btn-grp" style="text-align: center;">
                        <b-button variant="outline-primary"
                                  style="margin-right: 5px;"
                                  v-b-modal="`editGroup${row.mk_group_srl}`"
                                  @click="editName = row.gname">
                          수정
                        </b-button>


                        <b-button
                            variant="outline-danger"
                            @click="deleteGroup(row)"
                        >
                          삭제
                        </b-button>
                      </td>
                    </tr>

                    </tbody>
                  </table>
                </div>
                  </div>
              </b-modal>-->

        </span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-3 p-0">

        <!--        <div class="card">
                  <div class="card-body m-0">
                    <h5>그룹선택</h5>
                    <hr class="my-3"/>
                    <label>전체</label>
                    <div v-for="row in groupNameList" :key="row.mk_group_srl">
                      <label>{{ row.gname }}</label>
                    </div>
                    <hr class="my-3"/>

                  </div>
                </div>-->

        <div class="card">
          <div class="card-header">
            <h5 style="margin: 0;">단일 수신자 추가</h5>
          </div>
          <div class="card-body m-0">
            <div class="mb-3">
              <label class="form-label">고객명</label>
              <input class="form-control" v-model="newMember.name"/>
            </div>
            <div class="mb-3">
              <label class="form-label">전화번호</label>
              <input class="form-control" v-model="newMember.phone"
                     onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"/>
            </div>
            <b-button block
                      variant="primary"
                      style="width: 100%"
                      @click="addPhoneNum">
              등록
            </b-button>
          </div>
        </div>

        <div class="card">
          <div class="card-header">
            <h5 style="margin: 0">
              엑셀 파일 수신자 추가
            </h5>
          </div>
          <div class="card-body m-0">
            <div class="mb-3">
              <div style="display: flex; justify-content: space-between">
                <div>
                  <label class="form-label">수신자 엑셀 파일</label>
                </div>
                <div>
                  <button type="button"
                          class="btn btn-soft-light"
                          style="padding: 0 4px;"
                          @click="$bvModal.show('info')">
                    <i class="uil uil-question-circle"></i>
                  </button>
                  <b-modal
                      id="info"
                      ok-only
                      ok-title="닫기"
                      title="도움말">
                    <p style="margin: 0 0 16px 0;">
                      수신자 목록이 들어있는 엑셀파일을 선택해주세요.
                    </p>
                    <center>
                      <img style="border:2px solid blue; margin-bottom: 16px;" :src="require('@/assets/mcimages/excel_example.png')">
                    </center>
                    <p style="margin: 0; color: #dd4b39;">
                      ※ 위 이미지처럼 엑셀의 1번째줄은 제목줄로 해주시고,
                      <br>이름이 있는 부분의 제목은 '고객명',
                      <br>연락처가 있는 부분의 제목은 '전화번호'로 기입해주시면,
                      <br>해당 데이터가 어떤 열에 있든지 찾아서 입력해줍니다.
                    </p>
                  </b-modal>
                </div>
              </div>
              <input ref='fileRef' class="form-control" type="file" id="formFile" accept=".xls,.xlsx" @change="selectExcel"/>
            </div>
            <b-button
                block
                variant="primary"
                style="width: 100%"
                @click="uploadExcel">
              등록
            </b-button>
          </div>
        </div>
      </div>
      <div class="col-xl-9">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <!--              <div class="col-2">
                              <b-dropdown split text="그룹지정" variant="primary">
                                <b-dropdown-item-button
                                    v-for="row in groupNameList"
                                    :key="row.mk_group_srl"
                                    @click="changeGroup(row.mk_group_srl)"
                                >
                                  {{ row.gname }}
                                </b-dropdown-item-button>
                              </b-dropdown>
                            </div>-->
              <div class="col-5"></div>
              <div class="col-3">
                <select class="form-select" v-model="selectGroupName" @change="selectGroup(selectGroupName)">
                  <option
                      value="all"
                  >
                    전체회원
                  </option>
                  <option
                      v-for="row in groupNameList"
                      :key="row.mk_group_srl"
                      :value="row.mk_group_srl"
                  >
                    {{ row.gname }}
                  </option>
                </select>
              </div>
              <div class="col-4">

                <input ref="default_input"
                       class="form-control"
                       placeholder="검색어를 입력하세요"
                       @input="onFilterTextBoxChanged()"
                />
              </div>
            </div>

          </div>
          <div class="card-body">
            <ag-grid-vue
                style="height: calc(100vh - 420px); width: 100%; text-align: center;"
                class="ag-theme-alpine"
                :columnDefs="columnDefs"
                :rowData="talkList"
                :context="context"
                rowSelection="multiple"
                @grid-ready="onGridReady"
                enableCellTextSelection="true"
            />
          </div>

          <div class="card-footer">
            <div style="display: flex; justify-content: space-between">
              <div>
                <b-button
                    variant="success"
                    style="display: inline; margin-right: 10px;"
                    @click="downExcel"
                >
                  엑셀 다운로드
                </b-button>
                <b-button
                    v-if="false"
                    variant="danger"
                    style="display: inline"
                    @click="delAllMember"
                >
                  수신자 전체삭제
                </b-button>
              </div>

              <div style="display: flex;">
                <label
                    for="horizontal-firstname-input"
                    class="col-form-label"
                    style="display: inline; margin-right: 10px;"
                >그룹지정</label
                >
                <div style="display: inline; margin-right: 10px;">
                  <select class="form-select" v-model="selGroupSrl" @change="setGroupSrlValue(selGroupSrl)">
                    <option
                        value="select"
                    >
                      그룹목록
                    </option>
                    <option
                        v-for="row in groupNameList"
                        :key="row.mk_group_srl"
                        :value="row.mk_group_srl"
                    >
                      {{ row.gname }}
                    </option>
                  </select>
                </div>
                <div>
                  <b-button
                      variant="primary"
                      style="display: inline"
                      @click="changeGroup"
                  >
                    그룹지정하기
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.ag-header-cell-label {
  justify-content: center;
  padding: 0 !important;
}

.ag-cell {
  border-right: 1px solid !important;
  border-top: 0 !important;
  border-color: #f4f4f4 !important;
}

</style>
